import React from "react";
// import logo from "./logo.svg";
import "./App.css";
// import headShot from "./assets/head-shot.png";
import { Panel } from "./components/Panel";
function App() {
	return (
		<div id="app-wrapper" className="container-fluid">
			<div className="row">
				<Panel />

				<div className="content col-sm-9">
					<div className="row">
						<div className="col-12 col-md-6">
							<div className="card bg-light mb-3">
								<div className="card-header">Vue Google Map</div>
								<div className="card-body">
									<p className="card-text">A basic mapping app with an AWS serverless API.</p>
									<p className="card-text">
										<b>Front end:</b> VueJs, Vuex, Vue Router, Vuetify and Vue2-google-maps.
									</p>
									<p className="card-text">
										<b>Back end:</b> Serverless framework, AWS Lambda, App Api, DynamoDB.
									</p>
									<p className="card-text">
										<b>Authentication:</b> AWS Cognito.
									</p>
									<a href="https://map.juicybitz.com" rel="noopener noreferrer" target="_blank" className="btn btn-primary">
										Show Me
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="card bg-light  mb-3">
								<div className="card-header">Timezones</div>
								<div className="card-body">
									<p className="card-text">A simple little time zone app.</p>
									<p className="card-text">
										<b>Front end:</b> VueJs, Vuex, Vue Router, Vuetify.
									</p>
									<p className="card-text">
										<b>Back end:</b> Node, Express, Mongoose, MongoDb, Passport.
									</p>
									<p className="card-text">
										<b>Authentication:</b> Google Oauth2.
									</p>
									<a href="https://enigmatic-retreat-46693.herokuapp.com/" rel="noopener noreferrer" target="_blank" className="btn btn-primary">
										Show Me
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="card bg-light  mb-3">
								<div className="card-header">Icosahedron</div>
								<div className="card-body">
									<p className="card-text">A simple jQuery toy, I put together while digging into RxJs.</p>
									<p className="card-text">
										<b>Front end:</b> jQuery, RxJs.
									</p>
									<a href="http://juicy-icosahedron.s3-website-us-west-2.amazonaws.com/index.html" rel="noopener noreferrer" target="_blank" className="btn btn-primary">
										Show Me
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="card bg-light  mb-3">
								<div className="card-header">Common Areas</div>
								<div className="card-body">
									<p className="card-title">
										Senior Technical Engineer <small class="text-muted">April 2016 - July 2020</small>
									</p>
									<p className="card-text">I was the lead front end developer at Common Areas, A SaaS product for the commercial real estate and call center industries.</p>
									<a href="https://commonareas.com/" rel="noopener noreferrer" target="_blank" className="btn btn-primary">
										Common Areas
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="card bg-light  mb-3">
								<div className="card-header">Civica CMS</div>
								<div className="card-body">
									<p className="card-title">
										VP of Development <small class="text-muted">May 2000 - September 2015</small>
									</p>
									<p className="card-text">One of the original public sector content mangement sytems. </p>
									<p className="card-text">
										I was the architect and lead developer of the CMS. Built on the Microsoft webstack of the time: Classic asp, Javascript, jQuery, IIS, MS SQL server, and
										upgraded over the years.
									</p>
									<p className="card-text">We won several awards over the years and it's still in use today.</p>

									<a href="https://www.cityofpaloalto.org/" rel="noopener noreferrer" target="_blank" className="btn btn-primary mr-2">
										Palo Alto
									</a>
									<a href="https://www.ocgov.com/" rel="noopener noreferrer" target="_blank" className="btn btn-primary">
										Orange County
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* <iframe src="https://docs.google.com/document/d/e/2PACX-1vROxfjHZu9aYw2h5avECyQINoEAaOFglxA3NIobILTubhU8TIDG-w8sNAQ4I8uLVNHLWsNSfYXTjPRJ/pub?embedded=true"></iframe> */}
				</div>
			</div>
			{/* <h1>Hi, There!</h1> */}
		</div>
	);
}

export default App;
