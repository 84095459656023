import React, { Component } from "react";
import headShot from "../assets/head-shot.png";
import linkedinLogo from "../assets/LI-In-Bug.png";

class Panel extends Component {
	render() {
		return (
			<div className="panel col-sm-3 justify-content-center  mb-2">
				<div className="row justify-content-center py-1">
					<h4>Stuart Gregg</h4>
				</div>
				<div className="row justify-content-center py-1">
					<div className="image-box">
						<img src={headShot} className="rounded-circle" alt="Stuart"></img>
					</div>
				</div>
				<div className="row justify-content-center py-1">Web Developer</div>
				<div className="row justify-content-center py-1">
					<a href="https://docs.google.com/document/d/1lcuQgYQi5acyEzYP--65hZidHON2TfmfWxrtgfweTKA/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
						Resume
					</a>
				</div>
				<div className="row justify-content-center py-1">
					<a href="https://www.linkedin.com/in/stuart-gregg-72bb8614/" target="_blank" rel="noopener noreferrer">
						<img className="linked-in" src={linkedinLogo} alt="Linked In" />
					</a>
				</div>
			</div>
		);
	}
}

export { Panel };
